import { AuthLayout } from '@layout/index';

import SelectWorkspaceForm from './components/SelectWorkspaceForm';
const SelectWorkspace = () => {
  return (
    <AuthLayout>
      <div className="flex flex-col w-full gap-2 justify-center items-center">
        <div className="text-2xl font-bold">Select brand workspace</div>
        <div className="text-sm max-w-[317px] text-center">
          A brand workspace is where your raw material and results data are
          stored for each brand.
        </div>
      </div>
      <SelectWorkspaceForm />
    </AuthLayout>
  );
};

export default SelectWorkspace;
