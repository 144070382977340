import { FC } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { MainLayout } from '@layout/index';
import { isLoggedInUser } from '@utils/auth';

import RoutesPath from './constants';

const ProtectedRoute: FC = () => {
  const isAuthenticated = isLoggedInUser();

  if (!isAuthenticated) {
    return <Navigate replace to={RoutesPath.LOGIN} />;
  } else {
    return (
      <MainLayout>
        <Outlet />
      </MainLayout>
    );
  }
};

export default ProtectedRoute;
