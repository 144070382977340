import { useMutation } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { PASSWORD_RESET_MAIL_SENT } from '@constants/messages';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

export const useForgotPassword = () => {
  const client = createHttpClient();

  const mutation = useMutation(
    ({ email }: { email: string }) =>
      client({
        url: END_POINTS.AUTH.FORGOT_PASSWORD,
        method: HttpMethod.POST,
        data: { email },
      }),
    {
      onSuccess: () => {
        showAlert({
          type: AlertType.SUCCESS,
          message: PASSWORD_RESET_MAIL_SENT,
        });
      },
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to reset password',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return mutation;
};
