import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { EmailIcon } from '@assets/icons';
import { Button, Input, SuccessMessageTemplate } from '@components/index';
import { InputTypes } from '@enums/input';
import { AuthLayout } from '@layout/index';
import { email } from '@lib/regex';
import RoutesPath from '@routes/constants';

import { useForgotPassword } from './hooks/use-forgot-password';

type ForgetPasswordFormData = {
  email: string;
};

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ForgetPasswordFormData>({
    mode: 'onChange',
  });

  const navigate = useNavigate();

  const { mutateAsync: forgotPassword } = useForgotPassword();

  const [recoveryMailSent, setRecoveryMailSent] = useState(false);

  const onFormSubmit = async (data: ForgetPasswordFormData) => {
    try {
      await forgotPassword(data);
      setRecoveryMailSent(true);
    } catch (error) {
      console.warn(error);
    }
  };

  const onLoginClick = () => {
    navigate(RoutesPath.LOGIN);
  };

  const emailValidation = (value: string) => {
    if (!value.trim()) {
      return 'Email is required';
    }

    if (!email.test(value)) {
      return 'Invalid email address';
    }
  };

  if (recoveryMailSent)
    return (
      <SuccessMessageTemplate
        heading="Recovery email sent!"
        subHeading="If this email address belongs to an account, you’ll receive an
           email containing a link to reset your password"
      />
    );

  return (
    <AuthLayout>
      <div className="flex flex-col w-full gap-2 justify-center items-center">
        <div className="text-2xl font-bold">Forgot Password</div>
        <div className="text-sm max-w-[412px] text-center">
          Input your email below and we will send you a link to reset your
          password.
        </div>
      </div>
      <form
        className="flex flex-col w-full gap-4 justify-center"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Input
          placeholder="Email ID"
          type={InputTypes.TEXT}
          label="Email"
          customInputClass="focus:border-primary focus:bg-lightest"
          error={errors.email ? errors.email.message : ''}
          {...register('email', {
            validate: emailValidation,
          })}
        />
        <Button
          type="submit"
          variant="contained"
          label="Reset password"
          IconLeftComponent={EmailIcon}
          isLoading={isSubmitting}
          isDisabled={!isValid || isSubmitting}
        />
      </form>
      <div className="flex items-center">
        <span className="text-sm">Or</span>
        <Button
          label="Login"
          variant="text"
          onClick={onLoginClick}
          classOverride="!text-sm !ml-1"
        />
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
