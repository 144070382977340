import { Suspense, useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PageFallback } from '@components/index';
import { useGetCustomer } from '@hooks/use-get-customer';
import { useGetUser } from '@hooks/use-get-user';
import { useGetWorkspace } from '@hooks/use-get-workspace';
import { customer } from '@store/atoms/customer';
import { userProfile } from '@store/atoms/user';
import { workspace } from '@store/atoms/workspace';

const LayoutWrapper = () => {
  const user = useRecoilValue(userProfile);
  const currentWorkspace = useRecoilValue(workspace);
  const customerData = useRecoilValue(customer);

  const [isLoading, setIsLoading] = useState(true);

  const { isLoading: isLoadingUser } = useGetUser({
    enabled: !user.id,
  });

  const { isLoading: isLoadingCustomer } = useGetCustomer({
    enabled: !!user.id && !customerData.id,
  });

  const { isLoading: isLoadingWorkspace } = useGetWorkspace({
    enabled: !!user.id && !currentWorkspace.id,
  });

  useEffect(() => {
    if (
      user?.id &&
      currentWorkspace?.id &&
      !isLoadingUser &&
      !isLoadingCustomer &&
      !isLoadingWorkspace
    )
      setIsLoading(false);
  }, [
    user?.id,
    currentWorkspace?.id,
    isLoadingUser,
    isLoadingWorkspace,
    isLoadingCustomer,
  ]);

  if (isLoading) return <PageFallback />;

  return (
    <Suspense fallback={<PageFallback />}>
      <Outlet />
    </Suspense>
  );
};

export default LayoutWrapper;
