import { FC, useState } from 'react';

import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Input, SuccessMessageTemplate } from '@components/index';
import { PasswordActions, PasswordInputTypes } from '@enums/handle-password';
import { InputTypes } from '@enums/input';
import { AuthLayout } from '@layout/index';
import RoutesPath from '@routes/constants';

import { useResetPassword } from './hooks/use-reset-password';

interface HandlePasswordProps {
  type: PasswordActions;
}

const handlePasswordLabels = {
  [PasswordActions.CREATE]: {
    title: 'Set your password',
    subTitle: 'Let’s secure your account with a password',
    buttonLabel: 'Save',
    loginLinkLabel: 'Already have an account?',
    successHeader: 'Password reset successful',
    successSubHeader:
      'Your password has been successfully updated. You can now log in with your new password.',
    successButtonLabel: 'Proceed to login',
  },
  [PasswordActions.RESET]: {
    title: 'Create a new password!',
    subTitle: 'Kindly enter the new password',
    buttonLabel: 'Update Password',
    loginLinkLabel: 'Remember your password?',
    successHeader: 'Password changed',
    successSubHeader: 'Your password has been changed successfully',
    successButtonLabel: 'Back to login',
  },
};

type PasswordFormData = {
  [PasswordInputTypes.PASSWORD]: string;
  [PasswordInputTypes.CONFIRM_PASSWORD]: string;
};

const HandlePassword: FC<HandlePasswordProps> = ({ type }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    trigger,
  } = useForm<PasswordFormData>({
    mode: 'onChange',
  });

  const watchPassword = watch(PasswordInputTypes.PASSWORD);
  const watchConfirmPassword = watch(PasswordInputTypes.CONFIRM_PASSWORD);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const { mutateAsync: resetPassword } = useResetPassword();

  const {
    title,
    subTitle,
    buttonLabel,
    loginLinkLabel,
    successHeader,
    successSubHeader,
    successButtonLabel,
  } = handlePasswordLabels[type];

  const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(false);

  const onFormSubmit = async (data: PasswordFormData) => {
    try {
      await resetPassword({
        ...data,
        token: token as string,
      });
      setIsResetPasswordSuccess(true);
    } catch (error) {
      console.warn(error);
    }
  };

  const onLoginClick = () => {
    navigate(RoutesPath.LOGIN);
  };

  const passwordValidation = (password: string) => {
    if (!password.trim()) return 'New Password is required';

    const passwordLength = password.length;
    if (passwordLength < 10 || passwordLength > 64) {
      return 'Your password must be 10-64 characters and have at least 1 lowercase, 1 uppercase, 1 number and 1 special character.';
    }
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    // Check if password has atleast one special character. ie; character other than lower/upper case alphabets and numbers.
    const hasSpecialCharacter = /[^a-zA-Z0-9]/.test(password);

    const isValidPassword =
      hasLowercase && hasUppercase && hasNumber && hasSpecialCharacter;
    if (!isValidPassword) {
      return 'Your password must be 10-64 characters and have at least 1 lowercase, 1 uppercase, 1 number and 1 special character.';
    }

    //when both passwords are same, but first one is changed. Then only second password field should show error message
    if (password && watchConfirmPassword && password !== watchConfirmPassword)
      trigger(PasswordInputTypes.CONFIRM_PASSWORD);
  };

  const confirmPasswordValidation = (password: string) => {
    if (!password.trim()) {
      return 'Confirm Password is required';
    }

    if (watchPassword !== password) {
      return 'Password does not match';
    }
  };

  const getPasswordError = () => {
    if (isEmpty(errors?.password?.message)) {
      return '';
    }

    if (errors.password?.message) {
      return errors.password.message;
    }
  };

  const getConfirmPasswordError = () => {
    if (isEmpty(errors?.confirmPassword?.message)) {
      return '';
    }

    if (errors.confirmPassword?.message) {
      return errors.confirmPassword.message;
    }
  };

  if (isResetPasswordSuccess)
    return (
      <SuccessMessageTemplate
        heading={successHeader}
        subHeading={successSubHeader}
        buttonLabel={successButtonLabel}
        onButtonClick={onLoginClick}
      />
    );

  return (
    <AuthLayout>
      <div className="flex flex-col w-full gap-2 justify-center items-center">
        <div className="text-2xl font-bold">{title}</div>
        <div className="text-sm max-w-[412px] text-center">{subTitle}</div>
      </div>
      <form className="w-full space-y-4" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="space-y-1">
          <Input
            id={PasswordInputTypes.PASSWORD}
            placeholder="Choose new password"
            label="New Password"
            type={InputTypes.PASSWORD}
            customInputClass="focus:border-primary focus:bg-lightest"
            {...register(PasswordInputTypes.PASSWORD, {
              validate: passwordValidation,
            })}
            error={getPasswordError()}
          />
        </div>
        <Input
          id={PasswordInputTypes.CONFIRM_PASSWORD}
          placeholder="Confirm new password"
          label="Confirm new password"
          type={InputTypes.PASSWORD}
          customInputClass="focus:border-primary focus:bg-lightest"
          {...register(PasswordInputTypes.CONFIRM_PASSWORD, {
            validate: confirmPasswordValidation,
          })}
          error={getConfirmPasswordError()}
        />

        <Button
          variant="contained"
          type="submit"
          isLoading={isSubmitting}
          label={buttonLabel}
        />
      </form>
      <div className="flex items-center">
        <span className="text-sm">{loginLinkLabel}</span>
        <Button
          label="Try logging in"
          variant="text"
          onClick={onLoginClick}
          classOverride="!text-sm !ml-1"
        />
      </div>
    </AuthLayout>
  );
};

export default HandlePassword;
