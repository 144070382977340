import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { customer } from '@store/atoms/customer';

import { CustomerWhiteLabel } from './types';

export const useGetCustomer = ({
  enabled = true,
}: { enabled?: boolean } = {}) => {
  const client = createHttpClient();

  const setCustomer = useSetRecoilState(customer);

  const query = useQuery(
    QUERY_KEYS.CUSTOMER.GET_CUSTOMER,
    () => {
      return client<CustomerWhiteLabel>({
        url: END_POINTS.CUSTOMER.GET_CUSTOMER,
      });
    },
    {
      retry: 0,
      enabled,
      onSuccess: data => {
        setCustomer(data);
        if (data?.metadata) {
          document.documentElement.style.setProperty(
            '--primary-color',
            data.metadata.colorPrimary,
          );
          document.documentElement.style.setProperty(
            '--lighter-color',
            data.metadata.colorLighter,
          );
          document.documentElement.style.setProperty(
            '--lightest-color',
            data.metadata.colorLightest,
          );
          document.documentElement.style.setProperty(
            '--darker-color',
            data.metadata.colorDarker,
          );
          document.documentElement.style.setProperty(
            '--darkest-color',
            data.metadata.colorDarkest,
          );
        }
      },
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to fetch customer details',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return query;
};
