import { useMutation } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { EmailLoginCreds } from '../types';

export const useLogin = () => {
  const client = createHttpClient();

  const mutation = useMutation(
    ({ email, password }: EmailLoginCreds) =>
      client({
        url: END_POINTS.AUTH.LOGIN,
        method: HttpMethod.POST,
        data: { email, password },
      }),
    {
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to login',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return mutation;
};
