import { FC } from 'react';

interface LinkProps {
  label: string;
  href?: string;
}

const Link: FC<LinkProps> = ({ label, href }) => {
  return (
    <a
      className="underline font-semibold"
      target="_blank"
      href={href}
      rel="noreferrer"
    >
      {label}
    </a>
  );
};

export default Link;
