import { FC } from 'react';

import { BrandDnaiLogo } from '@assets/icons';

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => (
  <div className="h-screen w-screen flex justify-center items-center">
    <div className="w-1/2">
      <BrandDnaiLogo className="mx-auto mb-2" />
      <div className="flex flex-col max-w-[476px] w-full items-center mx-auto gap-8 p-4">
        {children}
      </div>
    </div>
  </div>
);

export default AuthLayout;
