import { lazy, useMemo } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { PasswordActions } from '@enums/handle-password';
import {
  ForgotPassword,
  HandlePassword,
  Login,
  SelectWorkspace,
} from '@pages/index';
import { isLoggedInUser } from '@utils/auth';

import RoutesPath from './constants';
import LayoutWrapper from './LayoutWrapper';
import ProtectedRoute from './ProtectedRoute';

const HomePage = lazy(() => import('@pages/home-page/HomePage'));
const ManageTeam = lazy(() => import('@pages/manage-team/ManageTeam'));

const AllRoutes = () => {
  const isAuthenticated = useMemo(() => isLoggedInUser(), []);

  return (
    <Routes>
      <Route
        path={RoutesPath.LOGIN}
        element={
          !isAuthenticated ? (
            <Login />
          ) : (
            <Navigate replace to={RoutesPath.BRAND_LIBRARY} />
          )
        }
      />
      <Route path={RoutesPath.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={RoutesPath.SELECT_WORKSPACE} element={<SelectWorkspace />} />
      <Route
        path={RoutesPath.RESET_PASSWORD}
        element={<HandlePassword type={PasswordActions.RESET} />}
      />
      <Route
        path={RoutesPath.CREATE_PASSWORD}
        element={<HandlePassword type={PasswordActions.CREATE} />}
      />
      <Route element={<LayoutWrapper />}>
        <Route element={<ProtectedRoute />}>
          <Route path={RoutesPath.BRAND_LIBRARY} element={<HomePage />} />
          <Route path={RoutesPath.MANAGE_TEAM} element={<ManageTeam />} />
        </Route>
      </Route>
      <Route
        path={RoutesPath.ALL}
        element={<Navigate replace to={RoutesPath.LOGIN} />}
      />
    </Routes>
  );
};

export default AllRoutes;
