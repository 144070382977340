import { Fragment, useState } from 'react';

import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { ErrorType } from '@enums/error';
import { AlertsContainer } from '@lib/alert/alert-manager';
import 'react-toastify/dist/ReactToastify.css';

import Routes from './routes';

import './App.css';

const MAX_API_CALL_COUNT = 2;

const retry = (failureCount: number, error: any) => {
  const errMsg = error.message;

  if (
    errMsg === ErrorType.APP_SESSION_EXPIRED ||
    failureCount >= MAX_API_CALL_COUNT
  ) {
    return false;
  }

  return true;
};

const App = () => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry,
          },
          mutations: {
            retry,
          },
        },
      }),
  );

  return (
    <RecoilRoot>
      <AlertsContainer />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(
  <Fragment>
    <App />
  </Fragment>,
);
