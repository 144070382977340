import { FC } from 'react';

import { isNil } from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Input } from '@components/index';
import { END_POINTS } from '@constants/api-endpoints';
import { InputTypes } from '@enums/input';
import RoutesPath from '@routes/constants';

interface LoginFormProps {
  isSSOLogin?: boolean;
  iconComponent?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  buttonLabel?: string;
  onLoginButtonClick?: Function;
}

type LoginFormData = {
  email: string;
  password?: string;
};

const LoginForm: FC<LoginFormProps> = ({
  isSSOLogin = false,
  iconComponent = () => null,
  buttonLabel = 'Login',
  onLoginButtonClick,
}) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormData>({
    mode: 'onChange',
  });

  const onForgotPasswordButtonClick = () => {
    navigate(RoutesPath.FORGOT_PASSWORD);
  };

  const onFormSubmit = async (data: { email: string; password?: string }) => {
    try {
      if (isSSOLogin) {
        window.location.replace(
          `${"https://api.stg.brandtechbrain.com/api/"}${END_POINTS.AUTH.SSO_LOGIN}?email=${data?.email}`,
        );
      } else {
        const response = onLoginButtonClick && (await onLoginButtonClick(data));
        if (response?.error) {
          setError('password', {
            type: 'Login error',
            message: response.error?.message,
          });
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <form
      className="space-y-4 animate-slide-in overflow-hidden"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <Input
        id="email"
        placeholder="Email ID"
        label="Email"
        {...register('email', {
          required: {
            value: true,
            message: 'Please enter an email address',
          },
        })}
        type={InputTypes.EMAIL}
        customInputClass="focus:border-primary focus:bg-lightest"
        error={isNil(errors.email?.message) ? '' : errors.email.message}
      />
      {!isSSOLogin && (
        <div className="flex flex-col">
          <Input
            id="password"
            placeholder="Enter your passsword"
            label="Password"
            type={InputTypes.PASSWORD}
            customInputClass="focus:border-primary focus:bg-lightest"
            {...register('password', {
              required: {
                value: true,
                message: 'Please enter a password',
              },
            })}
            error={
              isNil(errors.password?.message) ? '' : errors.password.message
            }
            showErrorMessage={false}
          />
          <div className="flex justify-between mt-1">
            {typeof errors?.password?.message === 'string' && (
              <span className="text-red-1 text-xs">
                {errors?.password?.message}
              </span>
            )}
            <Button
              label="Forgot Password?"
              onClick={onForgotPasswordButtonClick}
              variant="text"
              classOverride="!ml-auto"
            />
          </div>
        </div>
      )}
      <Button
        variant="contained"
        type="submit"
        label={buttonLabel}
        IconLeftComponent={iconComponent}
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
      />
    </form>
  );
};

export default LoginForm;
