import { useMutation } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { PASSWORD_RESET_SUCCESS } from '@constants/messages';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

export const useResetPassword = () => {
  const client = createHttpClient();

  const mutation = useMutation(
    (data: { password: string; confirmPassword: string; token: string }) =>
      client({
        url: END_POINTS.AUTH.RESET_PASSWORD,
        method: HttpMethod.POST,
        data: data,
      }),
    {
      onSuccess: () => {
        showAlert({
          type: AlertType.SUCCESS,
          message: PASSWORD_RESET_SUCCESS,
        });
      },
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to reset password',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return mutation;
};
